// src/ApiService.js
import axios from 'axios';
import {OrderConfirmBody} from "../pages/address/address";
import {CatalogProduct} from "../ultils/localStorageUtils";

class ApiService {
    url = 'https://genzstore2k.com';
    // url = 'http://localhost:3000';

    private async _get(api: string) {
        try {
            const response = await axios.get(`${this.url}/${api}`);
            return response.data.data
        } catch (ex) {
            alert(`${api} -- ERROR` + JSON.stringify(ex))
        }
    }

    private async _post(api: string, body: any) {
        try {
            const response = await axios.post(`${this.url}/${api}`, body);
            return response.data.data
        } catch (ex) {
            alert(`${api} -- ERROR` + JSON.stringify(ex))
        }
    }

    async getProducts(): Promise<CatalogProduct[]> {
        return await this._get('products/web')
    }

    async getImageBy(productId: number) {
        let api = `products/image/${productId}`
        return await this._get(api);
    }

    async getAddress(type: number, id?: number) {
        let api = `ghn/searchAddress?type=${type}`
        if (id) {
            api = `${api}&id=${id}`
        }
        return await this._get(api);
    }

    async checkout(body: OrderConfirmBody) {
        let api = `ghn/createdFromWeb`
        return await this._post(api, body);
    }

}

const apiService = new ApiService();
export default apiService;
