import React from "react";
import './image_product.css';

const ImageProduct = (image: string, discount: number) => {
    console.log(image)
    return (
        <div className="product-image-container">
            <img src={image ?? '/holder.png'} alt="Product" className="product-image"/>
            <div className="discount-badge">-{discount}%</div>
        </div>
    )
}

export default ImageProduct