import React, {useState} from 'react';
import './atrribute_product.css';
import {Attribute, Product} from "../../../ultils/localStorageUtils";

const AttributeProduct: React.FC<{
    product: Product,
    onSizeChange: (id: number) => void
}> = ({product, onSizeChange}) => {
    const [currentProduct, setCurrentProduct] = useState<Product>(product);

    const attributes = currentProduct.sizes;


    const handleColorClick = (attribute: Attribute) => {
        const updatedProduct = {
            ...currentProduct,
            sizeSelect: attribute.id,
        };
        setCurrentProduct(updatedProduct);
        onSizeChange(attribute.id);
    };

    return (
        <div>
            <h4>Kích thước</h4>
            <div className="circle-container">
                {attributes.map((attribute) => (
                    <div key={attribute.id} className="color-item" onClick={() => handleColorClick(attribute)} style={{
                        backgroundColor: currentProduct.sizeSelect === attribute.id ? 'powderblue' : 'white',
                    }}>
                        <span>{attribute.name}</span>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default AttributeProduct;
