import React, {useState} from 'react';
import './catalog_item.css';
import {CatalogProduct, Product} from "../../../ultils/localStorageUtils";
import {formatPrice} from "../../../ultils/price_utils";
import QuantitySelector from "../../detail/components/quantity_selector";
import ImageProduct from "./image_product/image_product";
import {useNavigate} from "react-router-dom";

interface CatalogItemProp {
    catalog: CatalogProduct,
    productClick: (addToCard: boolean, product: Product) => void
}

const CatalogItem: React.FC<CatalogItemProp> = ({catalog, productClick}) => {
    const [products, setProducts] = useState<Product[]>(catalog.products)
    const [error, setError] = useState<string | null>(null);


    const navigate = useNavigate();

    const handleProductClick = (product: Product) => {
        navigate(`/detail/${product.id}/${product.colorId}`);
    };

    const stopParentClick = (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
    };

    const buttonClick = (event: { stopPropagation: () => void; }, product: Product, addCart: boolean) => {
        event.stopPropagation();
        if (product.sizeSelect == null) {
            alert('Vui lòng chọn kích thước')
            return
        }
        productClick(addCart, product)
    };

    return (
        <div className="catalog">
            <h3 className="catalog-title">{catalog.title}</h3>
            <div className="product-grid">
                {products.map((product, index) => (
                    <div className="catalog-product-item" key={index} onClick={() => handleProductClick(product)}>
                        {ImageProduct(product.images[0], Math.floor((product.originPrice - product.salePrice) * 100 / product.originPrice))}
                        <h5>{product.productName + ' ' + product.colorName}</h5>
                        <div style={{gap: 6, display: "flex", justifyContent: "start", alignItems: "center"}}>
                            <span style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                color: "grey",
                                textDecoration: "line-through",
                            }}>{formatPrice(product.originPrice)}</span>
                            <span style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: 20,
                            }}>{formatPrice(product.salePrice)}</span>
                        </div>

                        <div className="select-attribute" onClick={stopParentClick}>
                            <QuantitySelector initQuantity={product.quantity} quantityChange={(value) => {
                                product.quantity = value
                            }}/>
                            <select
                                value={product.sizeSelect || ''}
                                onChange={(e) => {
                                    const newProduct = products.map((item) => {
                                        if (item.id === product.id && item.colorId === product.colorId) {
                                            return {
                                                ...item,
                                                sizeSelect: Number(e.target.value)
                                            }
                                        }
                                        return item
                                    })
                                    setProducts(newProduct)
                                }}>
                                <option value="" disabled selected hidden>Chọn kích thước</option>
                                {product.sizes.map(sizes => (
                                    <option key={sizes.id} value={sizes.id}>
                                        {sizes.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="product-buttons">
                            <button
                                type="button"
                                className="btn-cart"
                                onClick={(event) => buttonClick(event, product, true)}
                                aria-disabled="false">
                                <img
                                    className="cartImg"
                                    alt="icon-add-to-cart"
                                    src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/0f3bf6e431b6694a9aac.svg"
                                />
                            </button>
                            <button
                                type="button"
                                className="btn-buy"
                                onClick={(event) => buttonClick(event, product, false)}
                                aria-disabled="false">
                                Mua ngay
                            </button>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    );
};
export default CatalogItem;