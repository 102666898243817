import React from 'react';
import './banner.css';

const Banner = () => {
    return (
        <div className="banner-container">
            <img src='/banner.png' className="banner-image" alt='Banner'/>
            <h3 className='phone-number'><a href="tel:0342092686">0342.092.686 </a></h3>
        </div>
    );
};

export default Banner;
