import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AddressPopup from "../../pages/address/address";
import {Product} from "../localStorageUtils";


interface DialogAddressProps {
    openDialog: boolean
    onClose: () => void
    products: Product[]
}

const DialogAddress: React.FC<DialogAddressProps> = ({openDialog, onClose, products}) => {
    return (<Dialog onClose={onClose} open={openDialog}>
        <AddressPopup onClose={onClose} products={products}/>
    </Dialog>)
}
export default DialogAddress;