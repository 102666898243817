import React, {useEffect, useState} from "react";
import {
    cartKey,
    getProductsFromLocalStorage,
    Product,
    saveProductsToLocalStorage
} from "../../ultils/localStorageUtils";
import AttributeProduct from "../detail/components/attribute_product";
import './checkout.css'
import QuantitySelector from "../detail/components/quantity_selector";
import {formatPrice} from "../../ultils/price_utils";
import DialogAddress from "../../ultils/dialog/dialog_app";

const mergeProducts = (products: Product[]): Product[] => {
    const mergedProducts: { [key: string]: Product } = {};
    products.forEach(product => {
        const key = `${product.id}-${product.sizeSelect}-${product.colorId}`;
        if (mergedProducts[key]) {
            mergedProducts[key].quantity = (mergedProducts[key].quantity ?? 0) + (product.quantity ?? 1);
        } else {
            mergedProducts[key] = {...product};
        }
    });
    return Object.values(mergedProducts);
};

const Checkout: React.FC = () => {
    const [products, setProducts] = useState<Product[]>(getProductsFromLocalStorage(cartKey));
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    useEffect(() => {
        saveProductsToLocalStorage(cartKey, products);
    }, [products]);
    const sizeProductChange = (productId: number, colorId: number, sizeId: number) => {
        const newProducts = products.map((product) => {
            if (product.id === productId && product.colorId === colorId) {
                return {...product, sizeSelect: sizeId}
            }
            return product
        })
        const a = mergeProducts(newProducts)
        console.log(a)
        setProducts(a);
    };

    const deleteProduct = (productId: number, colorId?: number, sizeId?: number) => {
        const newProducts = products.filter((product) => !(product.id === productId && product.sizeSelect === sizeId && product.colorId === colorId))
        saveProductsToLocalStorage(cartKey, newProducts)
        setProducts(newProducts);
    }
    if (products.length === 0) {
        return (<div style={{textAlign: "center"}}><h1 style={{textAlign: "center"}}>Không có dữ liệu</h1></div>)
    }
    return (
        <div className="main-checkout">
            <div className="checkout-container">
                {products.map(product => (
                    <div key={`${product.id}-${product.colorId}-${product.sizeSelect}`} className="product-item">
                        <div className="product-info">
                            <img src={product.images[0]} alt={product.productName} className="image-cart"/>
                            <div>
                                <h4 className="product-name">{product.productName} {product.colorName}</h4>
                                <p className="product-price">Giá: {formatPrice(product.salePrice)}</p>
                            </div>
                            <p className="btn-clear"
                               onClick={() => deleteProduct(product.id, product.colorId, product.sizeSelect)}>Xoá</p>
                        </div>
                        <div className="product-details">
                            <AttributeProduct product={product}
                                              onSizeChange={(id) => sizeProductChange(product.id, product.colorId, id)}/>
                            <QuantitySelector initQuantity={product.quantity} quantityChange={(value) => {
                                product.quantity = value
                            }}/>
                        </div>
                    </div>
                ))}
            </div>
            <div className="footer-checkout">
                <button className="btn-checkout" onClick={() => setIsPopupOpen(true)}>Đặt
                    hàng {formatPrice(products.map((e) => e.salePrice).reduce((f, s) => f + s))}</button>
                <DialogAddress openDialog={isPopupOpen} onClose={() => {
                    setIsPopupOpen(false)
                }} products={products}/>
            </div>
        </div>

    );
};

export default Checkout;