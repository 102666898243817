// src/Home.tsx
import React, {useEffect, useState} from 'react';
import './home.css';
import Banner from './bannner/banner';
import {
    cartKey,
    CatalogProduct,
    getCatalogProduct,
    getProductsFromLocalStorage,
    Product,
    saveCatalogProduct,
    saveProductsToLocalStorage
} from "../../ultils/localStorageUtils";
import '../../App.css'
import Header from "../header/header";
import DialogAddress from "../../ultils/dialog/dialog_app";
import apiService from "../../di/api_service";
import CatalogItem from "./catalog/catalog_item";
import {Link} from "react-router-dom";

const Home: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [cartItems, setCartItems] = useState<Product[]>(() => getProductsFromLocalStorage(cartKey));
    const [buyProduct, setBuyProduct] = useState<Product[]>([])
    const [catalogs, setCatalogs] = useState<CatalogProduct[]>([]);

    useEffect(() => {
        const cachedCatalogs = getCatalogProduct();
        if (cachedCatalogs) {
            setCatalogs(cachedCatalogs);
        }
        apiService.getProducts().then(data => {
            //TODO cần check nếu có data thay đổi thì mới setCatalog
            setCatalogs(data);
            saveCatalogProduct(data)
        }).catch(error => {
            setError('Failed to fetch products ' + error);
        })
    }, []);

    useEffect(() => {
        saveProductsToLocalStorage(cartKey, cartItems)
    }, [cartItems]);

    const productClick = (addToCart: boolean, product: Product) => {
        if (addToCart) {
            const item = cartItems.find((e) => e.id === product.id && e.colorId === product.colorId && e.sizeSelect === product.sizeSelect)
            if (item !== undefined) {
                const newProducts = cartItems.map((e) => {
                    if (e.id === product.id && e.sizeSelect === product.sizeSelect) {
                        return {
                            ...e,
                            quantity: e.quantity + 1
                        }
                    }
                    return e
                })
                setCartItems(newProducts);
            } else {
                const updatedCartItems = [...cartItems, product];
                setCartItems(updatedCartItems);
            }
            alert('Thêm giỏ hàng thành công')
        } else {
            setBuyProduct([product])
        }
    };


    if (catalogs.length === 0) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>{error}</div>;
    }
    return (
        <div className="page-container">
            <Header cartCount={cartItems.length}/>
            <div className="scrollable-content">
                <Banner/>
                <div className="main-content">
                    {catalogs.map((catalog) => (
                        <CatalogItem catalog={catalog} productClick={productClick}/>
                    ))}
                </div>
                <a href="https://shopee.vn/genzstore.2k" target="_blank" rel="noopener noreferrer">
                    Genz Shop
                </a>
                <p/>
                <a href="https://shopee.vn/genzstore.2k" target="_blank" rel="noopener noreferrer">
                    The Jeans
                </a>

            </div>
            <DialogAddress openDialog={buyProduct.length > 0} onClose={() => {
                setBuyProduct([])
            }} products={buyProduct}/>
        </div>
    );
};

export default Home;
