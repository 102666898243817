import {Product} from "../../../ultils/localStorageUtils";

import React, {useState} from 'react';

import './slider_image.css';
import ImageGallery, {ReactImageGalleryItem} from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css'

const SliderImage: React.FC<{ data: string[] }> = ({data}) => {
    const [images, setImages] = useState<ReactImageGalleryItem[]>(data.map<ReactImageGalleryItem>((e) => {
        return {original: e, thumbnail: e}
    }));

    if (images.length === 0) {
        return (<div>Loading...</div>)
    }
    return (
        <ImageGallery items={images}
                      showPlayButton={false}
                      showFullscreenButton={false}
        />
    )
};

export default SliderImage;
