import React from 'react';
import './App.css';
import Home from './pages/home/home';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DetailProduct from "./pages/detail/detail_product";
import Checkout from "./pages/checkout/checkout";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/detail/:productId/:colorId" element={<DetailProduct/>}/>
                <Route path="/checkout" element={<Checkout/>}/>
            </Routes>
        </Router>
    );
}

export default App;
