// localStorageUtils.ts
export interface Attribute {
    id: number
    name: string
}

export enum AttributeType {
    color, size
}

export interface CatalogProduct {
    title: string
    products: Product[]
}

export interface Product {
    id: number
    productName: string
    originPrice: number
    salePrice: number
    amountSold: number
    images: string[]
    colorId: number
    colorName: string
    sizeSelect?: number
    sizes: Attribute[]
    quantity: number
}

export interface ProductTemp {
    id: number;
    productName: string;
    price: number;
    image: string;
    amountSold: number;
    salePrice: number;
    colorIds: number[];
    colors: Attribute[];
    sizeIds: number[];
    sizes: Attribute[];
    detail?: string
}

export const catalogProduct = 'catalogs'
export const productKey = 'products'
export const cartKey = 'carts'

export const saveCatalogProduct = (catalogs: CatalogProduct[]) => {
    localStorage.setItem(catalogProduct, JSON.stringify(catalogs));
}
export const getCatalogProduct = (): CatalogProduct[] => {
    const existingProducts = localStorage.getItem(catalogProduct) ?? '[]'
    return JSON.parse(existingProducts)
}
export const saveProductsToLocalStorage = (key: string, products: Product[]) => {
    localStorage.setItem(key, JSON.stringify(products));
}

export const getProductsFromLocalStorage = (key: string): Product[] => {
    const existingProducts = localStorage.getItem(key);
    return existingProducts ? JSON.parse(existingProducts) : [];
}
export const getProductBy = (productId: string, colorId: string): Product | undefined => {
    const catalogs = getCatalogProduct()
    const products = catalogs.map((e) => e.products).reduce((f, s) => [...f, ...s])
    return products.find((e) => e.id === Number(productId) && e.colorId === Number(colorId))
}
export const addCart = (product: Product) => {
    const products: Product[] = getProductsFromLocalStorage(cartKey)
    const item = products.find((e) => e.id === product.id && e.sizeSelect === product.sizeSelect)
    if (item !== undefined) {
        item.quantity = (item.quantity ?? 0) + product.quantity
    } else {
        products.push(product)
    }
    saveProductsToLocalStorage(cartKey, products)
}

