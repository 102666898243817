import React, {useEffect, useState} from 'react';
import './quantity_selector.css';

const QuantitySelector: React.FC<{
    quantityChange: (quantity: number) => void,
    initQuantity: number
}> = ({quantityChange, initQuantity}) => {
    const [quantity, setQuantity] = useState(initQuantity);
    useEffect(() => {
        setQuantity(initQuantity);
    }, [initQuantity]);
    const increaseQuantity = () => {
        const qtt = quantity + 1
        quantityChange(qtt)
        setQuantity(qtt);
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            const qtt = quantity - 1
            quantityChange(qtt)
            setQuantity(qtt);
        }
    };

    return (
        <div className="quantity-selector">
            <span style={{fontSize: 14}}>Số lượng</span>
            <button onClick={decreaseQuantity} disabled={quantity === 1}>-</button>
            <span>{quantity}</span>
            <button onClick={increaseQuantity}>+</button>
        </div>
    );
};

export default QuantitySelector;
