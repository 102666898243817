import React, {useEffect, useState} from 'react';
import {District, Province, Ward} from "../../model/address";
import './address.css'
import '../../App.css'
import {cartKey, Product, saveProductsToLocalStorage} from "../../ultils/localStorageUtils";
import apiService from "../../di/api_service"
import Api_service from "../../di/api_service";

export interface OrderConfirmBody {
    phone: string
    name: string
    address: string
    provinceId?: number
    districtId?: number
    wardCode?: string
    note?: string
    productName: string
    orderJson: string
}

interface AddressPopupProps {
    products: Product[]
    onClose: () => void;
}

const AddressPopup: React.FC<AddressPopupProps> = ({products, onClose}) => {
    const [provinces, setProvinces] = useState<Province[]>([]);
    const [districts, setDistricts] = useState<District[]>([]);
    const [wards, setWards] = useState<Ward[]>([]);

    const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
    const [selectedDistrict, setSelectedDistrict] = useState<District | null>(null);
    const [selectedWard, setSelectedWard] = useState<Ward | null>(null);


    const [inputName, setInputName] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [inputNote, setInputNote] = useState('');
    const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputName(event.target.value);
    };
    const phoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputPhone(event.target.value);
        setPhoneError('');
    };
    const noteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputNote(event.target.value);
    };
    const handleBlur = () => {
        const phoneRegex = /^[0-9]{10,15}$/; // Chỉ cho phép số từ 10 đến 15 chữ số
        // Kiểm tra tính hợp lệ
        if (!phoneRegex.test(inputPhone)) {
            setPhoneError('Số điện thoại không hợp lệ');
        } else {
            setPhoneError('');
        }
    };


    useEffect(() => {
        apiService.getAddress(1)
            .then(provinces => {
                const data: Province[] = provinces
                setProvinces(data)
            })
            .catch(error => console.error('Error fetching provinces:', error));
    }, []);
    useEffect(() => {
        if (selectedProvince) {
            apiService.getAddress(2, selectedProvince.ProvinceID)
                .then(districts => {
                    if (districts == null) {
                        setDistricts([])
                        return
                    }
                    const data: District[] = districts
                    setDistricts(data);
                    setSelectedDistrict(null);
                    setSelectedWard(null);
                })
                .catch(error => console.error('Error fetching districts:', error));
        } else {
            setDistricts([]);
            setWards([]);
        }
    }, [selectedProvince]);
    useEffect(() => {
        if (selectedDistrict) {
            apiService.getAddress(3, selectedDistrict.DistrictID)
                .then(wards => {
                    if (wards == null) {
                        setWards([])
                        return
                    }
                    const data: Ward[] = wards
                    setWards(data);
                    setSelectedWard(null); // Reset selected ward when district changes
                })
                .catch(error => console.error('Error fetching wards:', error));
        } else {
            setWards([]);
        }
    }, [selectedDistrict]);

    let productName = ''
    let orderJson: any[] = []
    products.forEach((e) => {
        const quantity = e.quantity ?? 1
        const pName = e.productName
        const colorSelect = e.colorId
        const colorName = e.colorName
        const sizeSelect = e.sizeSelect
        const sizeName = e.sizes.find((e) => e.id === sizeSelect)?.name ?? ''
        productName = productName.concat(`${quantity} ${pName} ${colorName},${sizeName}`).concat("\n")
        orderJson.push({
            productId: e.id,
            colorId: colorSelect,
            sizeId: e.sizeSelect,
            quantity: quantity
        })
    })

    const validInfoUser = () => {
        const name = inputName
        const phone = inputPhone
        let error
        if (name === '') {
            error = 'Vui lòng nhập tên'
        } else if (phone === '') {
            error = 'Nhập số điện thoại'
        }
        if (error) {
            alert(error)
            return
        }
        const provinceId = selectedProvince?.ProvinceID ?? 0
        const districtId = selectedDistrict?.DistrictID ?? 0
        const wardCode = selectedWard?.WardCode ?? ''
        const address = `${selectedWard?.WardName ?? ''}-${selectedDistrict?.DistrictName ?? ''}-${selectedProvince?.ProvinceName ?? ''}`

        const data: OrderConfirmBody = {
            name: name,
            phone: phone,
            provinceId: provinceId,
            districtId: districtId,
            wardCode: wardCode,
            note: inputNote,
            address: address,
            productName: productName,
            orderJson: JSON.stringify(orderJson),
        }
        apiService.checkout(data).then(r => {
            const id = r['id']
            if (id) {
                saveProductsToLocalStorage(cartKey, [])
                onClose()
                alert('Đặt thành công, shop sẽ liên hệ lại với bạn')
            }
        })
    };


    return (
        <div className="popup-overlay">
            <span className="close-icon" onClick={() => {
                onClose();
            }}>Đóng</span>
            <h3 style={{textAlign: "center", margin: 4}}>Nhập thông tin</h3>
            <span style={{fontWeight: "bold", color: "blue", whiteSpace: "pre-line"}}>{productName}</span>
            <div style={{display: "flex", gap: 10}}>
                <div style={{flex: 2}}>
                    <input type="tel" placeholder="Số điện thoại" value={inputPhone} onChange={phoneChange}
                           onBlur={handleBlur}/>
                    {phoneError && <span style={{color: 'red', fontSize: 14}}>{phoneError}</span>}
                </div>
                <input style={{flex: 1}} type="text" placeholder="Nhập tên" value={inputName} onChange={nameChange}/>
            </div>
            <select value={selectedProvince?.ProvinceID || ''} onChange={(e) => {
                const province = provinces.find(p => p.ProvinceID === Number(e.target.value));
                setSelectedProvince(province || null);
            }}>
                <option value="">Chọn Tỉnh/Thành phố</option>
                {provinces.map(province => (
                    <option key={province.ProvinceID} value={province.ProvinceID}>
                        {province.ProvinceName}
                    </option>
                ))}
            </select>
            <select value={selectedDistrict?.DistrictID || ''} onChange={(e) => {
                const district = districts.find(d => d.DistrictID === Number(e.target.value));
                setSelectedDistrict(district || null);
            }} disabled={!selectedProvince}>
                <option value="">Chọn quận/huyện</option>
                {districts.map(district => (
                    <option key={district.DistrictID} value={district.DistrictID}>
                        {district.DistrictName}
                    </option>
                ))}
            </select>
            <select value={selectedWard?.WardCode || ''} onChange={(e) => {
                const ward = wards.find(w => w.WardCode === e.target.value);
                setSelectedWard(ward || null);
            }} disabled={!selectedDistrict}>
                <option value="">Chọn Phường/Xã</option>
                {wards.map(ward => (
                    <option key={ward.WardCode} value={ward.WardCode}>
                        {ward.WardName}
                    </option>
                ))}
            </select>
            <input type="text" placeholder="Ghi chú" value={inputNote} onChange={noteChange}/>
            <button className="confirm-btn" onClick={validInfoUser}>Gửi thông tin
            </button>
        </div>
    );
};


export default AddressPopup;
