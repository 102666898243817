// Header.tsx
import React from 'react';
import './header.css';
import {Link} from "react-router-dom";

interface CartChange {
    cartCount: number;
}

const Header: React.FC<CartChange> = ({cartCount}) => {
    return (
        <header className="header">
            <img className="image-logo" src='/viet_nam.png' alt="logo"/>
            <input type="text" placeholder="Search..." className="search-input"/>
            <Link to={"/checkout"} className="cart-icon">
                <img
                    src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/0f3bf6e431b6694a9aac.svg"
                    alt="Cart" className="cart-image"/>
                {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
            </Link>
        </header>
    );
};

export default Header;
