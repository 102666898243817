import React, {useEffect, useState} from 'react';
import './detail_product.css';
import {useParams} from 'react-router-dom';
import SliderImage from "./components/slider_image";
import AttributeProduct from "./components/attribute_product";
import QuantitySelector from "./components/quantity_selector";
import {addCart, AttributeType, getProductBy, Product} from "../../ultils/localStorageUtils";
import DialogAddress from "../../ultils/dialog/dialog_app";
import {formatPrice} from "../../ultils/price_utils";

const DetailProduct = () => {
    const {productId, colorId} = useParams<{ productId: string, colorId: string }>();
    const [product, setProduct] = useState<Product | undefined>(undefined);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    useEffect(() => {
        if (productId && colorId) {
            const fetchedProduct = getProductBy(productId, colorId);
            setProduct(fetchedProduct);
        }
    }, [productId, colorId]);


    // const [cartProduct, setCartProduct] = useState<Product | null>(null);
    // const [cartItems, setCartItems] = useState<Product[]>(() => getProductsFromLocalStorage(cartKey));

    // useEffect(() => {
    //     saveProductsToLocalStorage(cartKey, cartItems)
    // }, [cartItems]);
    // useEffect(() => {
    //     const storedProduct = getProductById(Number(id))
    //     if (storedProduct) {
    //         setCartProduct({...storedProduct})
    //         setProduct(storedProduct);
    //     }
    // }, [id]);

    const detailString = 'Quần jeans baggy nam ống suông rộng màu xanh, đen vải bò dày dặn T1 - GenZ Store HN\n' +
        '\n' +
        'THÔNG TIN VỀ SẢN PHẨM QUẦN JEANS BAGGY NAM ỐNG SUÔNG RỘNG\n' +
        '- Xuất xứ: Việt Nam\n' +
        '- Chất liệu: 97% cotton, 3% spandex\n' +
        '- Màu sắc: Xanh Nhạt, Đen Trơn\n' +
        '- Kiểu dáng: Trơn\n' +
        '- Kích thước: Size 27 - 36\n' +
        '*Hãy nhắn tin cho shop để được tư vấn size chuẩn nhất với bạn!\n' +
        '- Đặc điểm nổi bật:\n' +
        '+ Quần jeans nam ống suông rộng, cạp cao, dáng đứng giúp tạo nên form cực chuẩn cho người mặc.\n' +
        '+ Quần jeans nút cài, có 4 túi lớn rất thuận tiện cho việc đựng smart phone hoặc ví cỡ bự.\n' +
        '+ Quần có màu xanh nhạt và đen trơn được nhuộm kỹ giúp hạn chế tối đa việc phai màu khi sử dụng.\n' +
        '+ Sản phẩm được đảm bảo với quy trình sản xuất chất lượng với đường may chắc chắn, tỉ mỉ từng chi tiết.\n' +
        '*Màu sản phẩm có thể sẽ chênh lệch thực tế một phần nhỏ, do ảnh hưởng về độ lệch màu của ánh sáng nhưng vẫn đảm bảo chất lượng.\n' +
        '\n' +
        'HƯỚNG DẪN BẢO QUẢN\n' +
        '- Lộn trái quần ra và sử dụng chất tẩy rửa nhẹ giúp quần mau sạch và hạn chế phai màu.\n' +
        '- Không sử dụng hóa chất trực tiếp lên sản phẩm, không ngâm quần quá lâu trong dung dịch tẩy.\n' +
        '- Là/ủi mặt trái ở nhiệt độ thấp hơn 110 độ C.\n' +
        '- Nên phơi sản phẩm ở nơi khô ráo, thoáng mát, hạn chế ánh nắng trực tiếp.\n' +
        '\n' +
        'CAM KẾT CỦA SHOP\n' +
        'Sản phẩm chuẩn chất lượng, đúng với hình ảnh và video mà shop cung cấp\n' +
        'Dịch vụ khách hàng luôn tận tâm và nhiệt tình\n' +
        'Nhắn tin cho shop để được tư vấn về size của sản phẩm\n' +
        'Thời gian chuẩn bị, đóng gói và giao hàng luôn được tối ưu nhất\n' +
        'Đổi trả sản phẩm nếu có lỗi từ nhà sản xuất theo quy định của Shopee:\n' +
        '1. Điều kiện áp dụng (trong vòng 03 ngày kể từ khi nhận sản phẩm):\n' +
        ' - Hàng hoá vẫn còn mới, chưa qua sử dụng\n' +
        '2. Trường hợp được chấp nhận:\n' +
        '- Hàng không đúng size, kiểu dáng như quý khách đặt hàng\t\n' +
        '- Không đủ số lượng, không đủ bộ như trong đơn hàng\n' +
        '3. Trường hợp không đủ điều kiện áp dụng chính sách:\t\t\n' +
        '- Quá 03 ngày kể từ khi Quý khách nhận hàng\t\t\t\t\t\n' +
        '- Gửi lại hàng không đúng mẫu mã, không phải hàng của Gen Z Store HN\n' +
        '\n' +
        'GenZ Store HN Cảm ơn bạn đã tin tưởng và mua sắm tại shop.\n' +
        'Quý khách hàng vui lòng liên hệ trực tiếp với shop để được giải quyết thắc mắc và vấn đề về sản phẩm (nếu có).\n' +
        'Mong rằng bạn sẽ hài lòng với chất lượng sản phẩm cũng như dịch vụ tư vấn, chăm sóc khách hàng của shop và đừng quên review, giới thiệu sản phẩm tới bạn bè của bạn nhé!\n' +
        '\n' +
        '#quan #jeans #baggy #nam #ong #suong #rong #formdung #xanhnhat #den #vai #bo #thoitrang #fashion #thoaimai #caocap #chatluong #genzstorehn'
    if (!product) {
        return <div>Loading</div>;
    }
    const handleProductChange = (id: number) => {
        product.sizeSelect = id;
    };

    return (
        <div>
            <SliderImage data={product.images}/>
            <div className="detail-product-container">
                <h3>{product.productName}</h3>
                <div style={{gap: 6, display: "flex", justifyContent: "start", alignItems: "center"}}>
                            <span style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                color: "grey",
                                textDecoration: "line-through",
                            }}>{formatPrice(product.originPrice)}</span>
                    <span style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: 20,
                    }}>{formatPrice(product.salePrice)}</span>
                </div>
                <AttributeProduct product={product} onSizeChange={handleProductChange}/>
                <QuantitySelector initQuantity={1} quantityChange={(value) => {
                    product!.quantity = value
                }}/>
                <div className="product-buttons">
                    <button
                        type="button"
                        className="btn-cart"
                        onClick={() => {
                            if (product.sizeSelect == null) {
                                alert('Vui lòng chọn Màu sắc hoặc kích thước')
                                return
                            }
                            addCart(product);
                            alert('Thêm vào giỏ hàng thành công')
                        }}
                        aria-disabled="false">
                        <img
                            className="cartImg"
                            alt="icon-add-to-cart"
                            src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/productdetailspage/0f3bf6e431b6694a9aac.svg"
                        />
                        <span className="card">Thêm</span>
                    </button>
                    <button
                        type="button"
                        className="btn-buy"
                        onClick={() => {
                            if (product.sizeSelect == null) {
                                alert('Chọn màu sắc, kích thước')
                                return;
                            }
                            setIsPopupOpen(true)
                        }}
                        aria-disabled="false">
                        Mua ngay
                    </button>
                </div>

                <div style={{textAlign: "center"}}>
                    <img className="bangSize"
                         src="/bangsize.jpg"
                         alt="bangsize"/>
                </div>

                <DialogAddress openDialog={isPopupOpen} onClose={() => {
                    setIsPopupOpen(false)
                }} products={[product]}/>
                <br/>
                <h4>Mô tả sản phẩm</h4>
                <div className="tags">
                          <span className="tag">
                              {detailString.split('\n').map((line, index) => (
                                  <p key={index}>{line}</p> // Hiển thị từng dòng trong thẻ <p>
                              ))}

                           </span>
                </div>
            </div>
        </div>

    );
};

export default DetailProduct;
